var totalLogos;
var current = [0,1,2,3,4];
var lastframe = -1;

function updateLogos(){
    var randLogo = Math.floor(Math.random()*5);
    if (randLogo == lastframe) {
        updateLogos();
        return
    }
    updateLogo(randLogo);
    lastframe = randLogo;
    TweenMax.delayedCall(2, updateLogos);
}

function updateLogo(num){
    var nextImage = Math.floor(Math.random()*totalLogos);

    for (var i = 0; i<5; i++) {
        if (nextImage == current[i]) {
            updateLogo(num);
            return
        }
    }

    for (var i = 0; i<totalLogos; i++) {
        var logo = jQuery('.companies:eq('+num+') .company:eq('+i+')');
        //logo.css('background','#ff00ff');
        if (logo.css('opacity') > .5) {
            if (nextImage == i) {
                updateLogo(num);
                return
            }
            TweenMax.to(logo, .75, {autoAlpha:0});
        } else {
            if (nextImage == i) {
                TweenMax.to(logo, .75, {autoAlpha:1, delay:.25});
                current[num] = i;
            }
        }
    }
}

jQuery(document).ready(function($) {
    totalLogos = jQuery('.companies:first .company').length;
    if(totalLogos > 0)
    {
        TweenMax.set(jQuery('.companies .company'), {autoAlpha:0});
        for (var j = 0; j<5 ; j++) {
            for (var i = 0; i<totalLogos; i++) {
                if (j == i && i < 5) {
                    TweenMax.set(jQuery('.companies:eq('+j+') .company:eq('+i+')'), {autoAlpha:1});
                }
            }
        }
        TweenMax.delayedCall(1, updateLogos);
    }
});

